import UAParser from "ua-parser-js";
import type { Ref } from "vue";
import { onMounted, onUnmounted, ref, useRequestHeaders } from "#imports";

export function getDeviceType() {
  const headers = useRequestHeaders();
  const parser = new UAParser(headers["user-agent"]);
  const parserResults = parser.getResult();

  const os = parserResults.os;
  const isMobile = os.name === "iOS" || os.name === "Android";

  return isMobile ? "mobile" : "desktop";
}

export function useResponsive<XL2, XL, LG, MD, SM, XS>(values: {
  xxl?: XL2;
  xl?: XL;
  lg?: LG;
  md?: MD;
  sm?: SM;
  xs?: XS;
}) {
  const deviceType = getDeviceType();

  const defaultValue = deviceType === "mobile" ? values.sm : values.lg;

  const value = ref(defaultValue) as Ref<XL2 | XL | LG | MD | SM | XS | undefined>;

  const onResize = () => {
    const windowWidth = window.innerWidth;

    value.value = values.xs;
    if (values.sm !== undefined && windowWidth >= 600) value.value = values.sm;
    if (values.md !== undefined && windowWidth >= 728) value.value = values.md;
    if (values.lg !== undefined && windowWidth >= 984) value.value = values.lg;
    if (values.xl !== undefined && windowWidth >= 1281) value.value = values.xl;
    if (values.xxl !== undefined && windowWidth >= 1496) value.value = values.xxl;
  };

  onMounted(() => {
    window.addEventListener("resize", onResize);
    onResize();
  });

  onUnmounted(() => {
    window.removeEventListener("resize", onResize);
  });

  return value;
}

export function useIsDesktop() {
  return useResponsive({
    lg: true,
    md: false,
  });
}
